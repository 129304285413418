const API_PATH = `${process.env.NEXT_PUBLIC_BASE_PATH}/api`

export const fetchApi = async ( path, body = null, method = 'GET') => {
    let finalBody = body
    if (method !== 'GET' && body) {
        finalBody = JSON.stringify(body)
    }
    const response = await fetch(`${API_PATH}/${path}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json'
        },
        ...finalBody
    })
    
    const responseData = await response.json()
    
    return responseData
    
}